import { PhoneOutlined } from '@ant-design/icons';
import { Button, Row } from 'antd';
import React from 'react';

function TaskTab(props) {
    return (
        <>
            <Row gutter={24} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                <Button type="primary" icon={<PhoneOutlined />}>Create Task</Button>
            </Row>
        </>
    );
}

export default TaskTab;