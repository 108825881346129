import Master from 'Layouts/Master';
import React from 'react';

function TaskPage(props) {
    return (
        <Master>
            Task
        </Master>
    );
}

export default TaskPage;