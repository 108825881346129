import PageHeader from 'Components/Header/PageHeader';
import Master from 'Layouts/Master';
import React from 'react';
import { Helmet } from 'react-helmet-async';

function VendorDetailPage(props) {
    return (
        <Master>
            <Helmet>
                <title>Chi Tiết Nhà Cung Cấp</title>
            </Helmet>
            <PageHeader title="Chi Tiết Nhà Cung Cấp" >
            </PageHeader>

        </Master>
    );
}

export default VendorDetailPage;