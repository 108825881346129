import { EditOutlined, PlusCircleTwoTone, UploadOutlined } from '@ant-design/icons';
import { faker } from '@faker-js/faker/locale/vi';
import { Button, Row, Space, Table, Tag } from 'antd';
import PageHeader from 'Components/Header/PageHeader';
import Master from 'Layouts/Master';
import { Link } from 'react-router-dom';

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text) => <Link>{text}</Link>,
    },
    {
        title: 'Phone',
        dataIndex: 'phone',
        key: 'phone',
    },
    {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
    },
    {
        title: 'Tags',
        key: 'tags',
        dataIndex: 'tags',
        render: (_, { tags }) => (
            <>
                {tags.map((tag) => {
                    let color = tag.length > 5 ? 'geekblue' : 'green';
                    if (tag === 'loser') {
                        color = 'volcano';
                    }
                    return (
                        <Tag color={color} key={tag}>
                            {tag.toUpperCase()}
                        </Tag>
                    );
                })}
            </>
        ),
    },
    {
        title: 'Action',
        key: 'action',
        fixed: 'right',
        align: 'right',
        render: (_, record) => (
            <Space size="middle">
                {/* <Link to={`/vendors/${record.key}`} style={{ color: 'blue' }}>Edit</Link> */}
                <Button type="dashed" icon={<EditOutlined />} href={`/vendors/${record.key}`}>Cập Nhật Thông Tin</Button>
                <Button type="primary" icon={<UploadOutlined />}>Cập Nhật Sản Phẩm</Button>
            </Space>
        ),
    },
];

const dataSource = Array.from({
    length: 100,
}).map((_, i) => ({
    key: i,
    name: faker.company.name(),
    phone: faker.phone.number(),
    address: faker.location.streetAddress(),
    tags: ['cool', 'teacher'],
}));

function VendorPage(props) {
    return (
        <Master>
            <PageHeader title="Danh Sách Nhà Cung Cấp" >
                <Button type="primary" icon={<PlusCircleTwoTone />}>Thêm NCC</Button>
            </PageHeader>
            <Row gutter={24}>
                <Table
                    columns={columns}
                    dataSource={dataSource}
                    style={{ width: '100%' }}
                    scroll={{
                        x: 'max-content',
                    }}
                />
            </Row>
        </Master>
    );
}

export default VendorPage;