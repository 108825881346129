import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Button, Form, Row } from 'antd';
import {
    Bold,
    ClassicEditor,
    Essentials,
    Heading,
    Indent,
    IndentBlock,
    Italic,
    Link,
    List,
    Paragraph,
    Table,
    Undo
} from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';

function NoteTab() {
    return (
        <Row gutter={24} style={{ marginBottom: 16 }}>
            <Form layout="vertical" style={{ width: '100%' }}>
                <Form.Item
                    name="note"
                    label="Note"
                >
                    <CKEditor
                        editor={ClassicEditor}
                        config={{
                            toolbar: [
                                'heading', '|', 'bold', 'italic', '|',
                                '|',
                                'bulletedList', 'numberedList', 'indent', 'outdent'
                            ],
                            plugins: [
                                Bold,
                                Essentials,
                                Heading,
                                Indent,
                                IndentBlock,
                                Italic,
                                Link,
                                List,
                                Paragraph,
                                Table,
                                Undo
                            ],
                            initialData: '<h1>Hello from CKEditor 5!</h1>',
                        }}
                    />
                </Form.Item>
                <Form.Item>
                    <Button type="primary">Save</Button>
                </Form.Item>
            </Form>

        </Row>
    );
}

export default NoteTab;