import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Card, Flex, Form, Input, Row } from 'antd';
import Guide from 'Layouts/Guide';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import authStore from 'store/authStore';


function LoginPage(props) {
    const { setUser } = authStore();

    const onFinish = (values) => {
        console.log('Received values of form: ', values);
        setUser(values);
    };

    return (
        <Guide>
            <Helmet>
                <title>Login</title>
            </Helmet>
            <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
                <Card title="Login" bordered={false} style={{ width: 350 }}>
                    <Form
                        name="login"
                        initialValues={{
                            remember: true,
                        }}
                        style={{
                            maxWidth: 360,
                        }}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Username!',
                                },
                            ]}
                        >
                            <Input prefix={<UserOutlined />} placeholder="Username" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Password!',
                                },
                            ]}
                        >
                            <Input prefix={<LockOutlined />} type="password" placeholder="Password" />
                        </Form.Item>
                        <Form.Item>
                            <Flex justify="space-between" align="center">
                                {/* <a href="">Forgot password</a> */}
                                <Link to="/forgot-password">Forgot password</Link>
                            </Flex>
                        </Form.Item>

                        <Form.Item>
                            <Button block type="primary" htmlType="submit">
                                Log in
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Row>
        </Guide>
    );
}

export default LoginPage;