import Master from 'Layouts/Master';
import React from 'react';

function SettingPage(props) {
    return (
        <Master>
            Setting
        </Master>
    );
}

export default SettingPage;