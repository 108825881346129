import { Card, Col, Row } from 'antd';
import { Helmet } from 'react-helmet-async';

function PageHeader(props) {
    return (
        <>
            <Helmet>
                <title>{props.title}</title>
            </Helmet>
            <Card bordered={false} style={{ marginBottom: 16 }}>

                <Row gutter={24}>
                    <Col span={18}>
                        <h2>{props.title}</h2>
                    </Col>
                    <Col span={6} style={{ textAlign: 'right' }}>
                        {props.children}
                    </Col>
                </Row>
            </Card >
        </>
    );
}

export default PageHeader;