import { Row } from 'antd';
import React from 'react';
import { useEffect } from 'react';

function OrderTab(props) {
    useEffect(() => {
        console.log('OrderTab');
    }, [])
    return (
        <Row>
            Order
        </Row>
    );
}

export default OrderTab;