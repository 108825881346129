import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

const authStore = create(
    persist((set) => ({
        user: null,
        setUser: (user) => set({ user }),
        removeUser: () => set({ user: null }),
        checkUser: () => (this.user == null) ? false : true,
    }),
        {
            name: 'auth-store', // name of the item in the storage (must be unique)
            storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
        },
    )
)

export default authStore