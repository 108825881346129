import Master from 'Layouts/Master';
import React from 'react';

function ProductDetailPage(props) {
    return (
        <Master>

        </Master>
    );
}

export default ProductDetailPage;