import { ArrowDownOutlined, ArrowUpOutlined, FileAddOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Col, Input, Row, Statistic, Table } from 'antd';
import PageHeader from 'Components/Header/PageHeader';
import Master from 'Layouts/Master';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import CreateDrawer from './Drawer/CreateDrawer';
import { faker } from '@faker-js/faker/locale/vi';

const { Search } = Input;

const columns = [
    {
        title: 'Tên hợp đồng',
        // width: 100,
        dataIndex: 'title',
        key: 'title',
        fixed: 'left',
    },
    {
        title: 'Tên khách hàng',
        dataIndex: 'name',
        key: 'name',
        // width: 150,
    },
    {
        title: 'Giá Trị',
        width: 100,
        dataIndex: 'age',
        key: 'age',
        fixed: 'left',
    },
    {
        title: 'Địa chỉ giao hàng',
        dataIndex: 'address',
        key: '2',
        width: 150,
    },
    {
        title: 'Tình trạng',
        dataIndex: 'address',
        key: '3',
        width: 150,
    },
    {
        title: 'Người Tạo',
        dataIndex: 'user',
        key: '4',
        width: 150,
    },
    {
        title: 'Thời gian tạo',
        dataIndex: 'created_at',
        key: '5',
        width: 150,
    },
    {
        title: 'Nhà Cung Cấp',
        dataIndex: 'vendor',
        key: '6',
        width: 150,
    },
    {
        title: 'Action',
        key: 'operation',
        fixed: 'right',
        width: 100,
        render: (text,) => <Link to={`/deals/${text.key}`}>Chi tiết</Link>
    },
];

let yourDate = new Date()
const dataSource = Array.from({
    length: 100,
}).map((_, i) => ({
    key: i,
    title: `Hợp đồng ${i}`,
    name: faker.company.name(),
    age: faker.finance.routingNumber(),
    address: faker.location.streetAddress(),
    user: 'admin',
    created_at: yourDate.toISOString().split('T')[0],
    vendor: faker.company.name(),
    operation: <Link to={`/deal/${i}`}>Chi tiết</Link>,
}));
function DealPage(props) {
    const [openDrawerTab, setOpenDrawerTab] = useState(false);
    const showDrawer = () => {
        setOpenDrawerTab(true);
    };
    const onClose = () => {
        setOpenDrawerTab(false);
    };

    return (
        <Master>
            <PageHeader title="Danh sách Deal">
                <CreateDrawer open={openDrawerTab} onClose={onClose} showDrawer={showDrawer} />
                <Button size='large' type="primary" icon={<FileAddOutlined />} iconPosition={'start'} onClick={showDrawer}>Tao Deal</Button>
            </PageHeader>
            <Row gutter={24} style={{ marginBottom: 16 }} >
                <Col span={6}>
                    <Card bordered={false}>
                        <Statistic
                            title="Active"
                            value={11.28}
                            precision={2}
                            valueStyle={{ color: '#3f8600' }}
                            prefix={<ArrowUpOutlined />}
                            suffix="%"
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card bordered={false}>
                        <Statistic
                            title="Idle"
                            value={9.3}
                            precision={2}
                            valueStyle={{ color: '#cf1322' }}
                            prefix={<ArrowDownOutlined />}
                            suffix="%"
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card bordered={false}>
                        <Statistic
                            title="Active"
                            value={11.28}
                            precision={2}
                            valueStyle={{ color: '#3f8600' }}
                            prefix={<ArrowUpOutlined />}
                            suffix="%"
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card bordered={false}>
                        <Statistic
                            title="Idle"
                            value={9.3}
                            precision={2}
                            valueStyle={{ color: '#cf1322' }}
                            prefix={<ArrowDownOutlined />}
                            suffix="%"
                        />
                    </Card>
                </Col>
            </Row>
            <Row gutter={24} style={{ marginBottom: 16, textAlign: 'right', marginLeft: 5 }} >
                <Search
                    placeholder="input search text"

                    style={{
                        width: 200,
                        marginRight: 10,
                    }}
                /><Search
                    placeholder="input search text"

                    style={{
                        width: 200,
                        marginRight: 10,
                    }}
                /><Search
                    placeholder="input search text"

                    style={{
                        width: 200,
                        marginRight: 10,
                    }}
                /><Search
                    placeholder="input search text"

                    style={{
                        width: 200,
                        marginRight: 10,
                    }}
                /><Search
                    placeholder="input search text"

                    style={{
                        width: 200,
                        marginRight: 10,
                    }}
                /><Search
                    placeholder="input search text"

                    style={{
                        width: 200,
                        marginRight: 10,
                    }}
                /><Search
                    placeholder="input search text"

                    style={{
                        width: 200,
                        marginRight: 10,
                    }}
                />
                <Button type="primary" icon={<SearchOutlined />} iconPosition={'start'}>
                    Search
                </Button>

            </Row>
            <Table
                columns={columns}
                dataSource={dataSource}
                scroll={{
                    x: 1500,
                }}
                summary={() => (
                    <Table.Summary >
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={2}>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={2} colSpan={6}>
                                Scroll Context
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={10}>Fix Right</Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Table.Summary>
                )}
                // antd site header height
                sticky={{
                    offsetHeader: 64,
                }}
            />
        </Master>
    );
}

export default DealPage;