import { PlusCircleTwoTone } from '@ant-design/icons';
import { Button, Row, Table } from 'antd';
import { createStyles } from 'antd-style';
import PageHeader from 'Components/Header/PageHeader';
import Master from 'Layouts/Master';
import { Link } from 'react-router-dom';
const useStyle = createStyles(({ css, token }) => {
    const { antCls } = token;
    return {
        customTable: css`
        ${antCls}-table {
          ${antCls}-table-container {
            ${antCls}-table-body,
            ${antCls}-table-content {
              scrollbar-width: thin;
              scrollbar-color: unset;
            }
          }
        }
      `,
    };
});
const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
    },
    {
        title: 'Age',
        dataIndex: 'age',
    },
    {
        title: 'Address',
        dataIndex: 'address',
    },
    {
        title: 'Action',
        key: 'operation',
        fixed: 'right',
        width: 100,
        render: (text) => <Link to={`/products/${text.key}`}>Edit</Link>,
    },
];
const dataSource = Array.from({
    length: 100,
}).map((_, i) => ({
    key: i,
    name: 'Panel Go ' + i,
    age: 32,
    address: `London, Park Lane no. ${i}`,
}));

function ProductPage(props) {
    const { styles } = useStyle();
    return (
        <Master>
            <PageHeader title="Danh Sách Sản Phẩm" >
                <Button type="primary" icon={<PlusCircleTwoTone />}>Thêm Sản Phẩm</Button>
            </PageHeader>
            <Row gutter={24}>
                <Table
                    className={styles.customTable}
                    columns={columns}
                    dataSource={dataSource}
                    pagination={true}
                    style={{ width: '100%' }}
                    scroll={{
                        x: 'max-content',
                    }}
                />
            </Row>
        </Master>
    );
}

export default ProductPage;