import {
    ApartmentOutlined,
    AreaChartOutlined,
    BarChartOutlined,
    CalendarOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    ProductOutlined,
    SettingOutlined,
    UploadOutlined,
    UserOutlined
} from '@ant-design/icons';
import { Avatar, Button, Dropdown, Flex, Layout, Menu, Space, theme } from 'antd';
import ProfileDrawer from 'Components/Drawer/ProfileDrawer';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';
import authStore from 'store/authStore';
const { Header, Sider, Content } = Layout;
const Master = (props) => {
    const navigate = useNavigate();
    const { setUser } = authStore();
    const [collapsed, setCollapsed] = useState(false);
    const [openProfile, setOpenOnProfile] = useState(false);
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const showDrawer = () => {
        setOpenOnProfile(true);
    };
    const onClose = () => {
        setOpenOnProfile(false);
    };

    const itemsLeft = [
        {
            key: '1',
            icon: <AreaChartOutlined />,
            label: 'Dashboard',
            onClick: () => {
                navigate('/');
            }
        },
        {
            key: '2',
            icon: <BarChartOutlined />,
            label: 'Deals',
            onClick: () => {
                navigate('/deals');
            }
        },
        {
            key: '3',
            icon: <UserOutlined />,
            label: 'Khách hàng',
            onClick: () => {
                navigate('/customers');
            }
        },
        {
            key: '4',
            icon: <CalendarOutlined />,
            label: 'Công Việc',
            onClick: () => {
                navigate('/tasks');
            }
        },
        {
            key: '5',
            icon: <ApartmentOutlined />,
            label: 'Nhà cung cấp',
            onClick: () => {
                navigate('/vendors');
            }
        },

        {
            key: '6',
            icon: <ProductOutlined />,
            label: 'Sản phẩm',
            onClick: () => {
                navigate('/products');
            }
        },
        {
            key: '7',
            icon: <SettingOutlined />,
            label: 'Cấu hình',
            onClick: () => {
                navigate('/settings');
            }
        },
    ];

    const itemsRight = [
        {
            key: '1',
            icon: <UserOutlined />,
            label: 'Update profile',
            onClick: showDrawer,
        },
        {
            key: '2',
            icon: <UploadOutlined />,
            label: 'Logout',
            onClick: () => {
                setUser(null);
                navigate('/');
            }
        },
    ];

    return (
        <>
            <Helmet>
                <title>CRM Riko</title>
            </Helmet>
            <Layout style={{ minHeight: '100vh' }}>
                <Sider trigger={null} collapsible collapsed={collapsed} collapsedWidth="0" >
                    <Link to="/">
                        <div className="logo-vertical" >CRM</div>
                    </Link>
                    <Menu
                        theme="dark"
                        mode="inline"
                        // defaultSelectedKeys={['1']}
                        items={itemsLeft}
                    />
                </Sider>
                <Layout>
                    <Header
                        style={{
                            padding: 0,
                            background: colorBgContainer,
                        }}
                    >
                        <Flex justify="space-between">
                            <Button
                                type="text"
                                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                                onClick={() => setCollapsed(!collapsed)}
                                style={{
                                    fontSize: '16px',
                                    width: 64,
                                    height: 64,
                                }}
                            />
                            <Flex className="nav-item dropdown" align='center' style={{ paddingRight: "20px" }}>
                                <ProfileDrawer showDrawer={showDrawer} onClose={onClose} open={openProfile} />
                                <Dropdown className='me-2' menu={{ items: itemsRight }} style={{ marginLeft: 10 }} trigger={['click']}>
                                    <Space>
                                        <Avatar icon={<UserOutlined />} />Chào Bạn : Admin
                                        <SettingOutlined style={{ marginLeft: 10, }} />
                                    </Space>
                                </Dropdown>
                            </Flex>
                        </Flex>
                    </Header>
                    <Content
                        style={{
                            margin: '24px 16px',
                            padding: 24,
                            background: colorBgContainer,
                            borderRadius: borderRadiusLG,
                        }}
                    >
                        {props.children}
                    </Content>
                </Layout>
            </Layout>
        </>
    );
};
export default Master;