import { Button, Col, Descriptions, Flex, Row, Tabs, Tag } from 'antd';
import PageHeader from 'Components/Header/PageHeader';
import Master from 'Layouts/Master';
import { faker } from '@faker-js/faker/locale/vi';
import { EditOutlined } from '@ant-design/icons';
import OrderTab from './Tab/OrderTab';
import ProductTab from './Tab/ProductTab';

function CustomerDetail(props) {
    const name = faker.person.fullName();

    return (
        <Master>
            <PageHeader title={`Thông tin khách hàng: ${name}`}>
            </PageHeader>
            <Row gutter={24}>
                <Col span={4}>
                    <Descriptions title="Thông Tin Cơ Bản" items={[
                        {
                            key: '1',
                            label: 'UserName',
                            children: name,
                        },
                        {
                            key: '2',
                            label: 'Telephone',
                            children: '1810000000',
                        },
                        {
                            key: '3',
                            label: 'Live',
                            children: 'Hangzhou, Zhejiang',
                        },
                        {
                            key: '4',
                            label: 'Remark',
                            children: 'empty',
                        },
                        {
                            key: '5',
                            label: 'Address',
                            children: faker.location.streetAddress(),
                        },
                        {
                            key: '6',
                            label: 'Email',
                            children: faker.internet.email(),
                            render: (text) => <a href={`mailto:${text}`}>{text}</a>
                        },
                        {
                            key: '7',
                            label: 'Nhóm',
                            children: <Flex gap="4px 0" wrap>
                                <Tag color="magenta">magenta</Tag>
                                <Tag color="red">red</Tag>
                                <Tag color="volcano">volcano</Tag>
                                <Tag color="orange">orange</Tag>
                                <Tag color="gold">gold</Tag>
                                <Tag color="lime">lime</Tag>
                                <Tag color="green">green</Tag>
                                <Tag color="cyan">cyan</Tag>
                                <Tag color="blue">blue</Tag>
                                <Tag color="geekblue">geekblue</Tag>
                                <Tag color="purple">purple</Tag>
                            </Flex>,
                        }
                    ]} column={1} />
                    <Button color="primary" variant="solid" icon={<EditOutlined />} iconPosition={'start'} style={{ marginTop: '16px' }}>Update</Button>
                </Col>
                <Col span={20}>
                    <Tabs
                        destroyInactiveTabPane={true}
                        defaultActiveKey="1"
                        items={[
                            {
                                label: `Đơn hàng`,
                                key: '1',
                                children: <OrderTab />,
                            },
                            {
                                label: `Sản phẩm`,
                                key: '2',
                                children: <ProductTab />,
                            },
                            {
                                label: `Note`,
                                key: '3',
                                children: <OrderTab />,
                            },
                            {
                                label: `Task`,
                                key: '4',
                                children: <OrderTab />,
                            },
                            {
                                label: `Thông tin mở rộng`,
                                key: '5',
                                children: <OrderTab />,
                            },
                        ]}
                    />
                </Col>
            </Row>
        </Master >
    );
}

export default CustomerDetail;