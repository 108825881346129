import { CloseCircleOutlined, EditOutlined, FileWordOutlined, LoadingOutlined, PrinterOutlined, ShoppingCartOutlined, SmileOutlined, SolutionOutlined, UserOutlined, WalletOutlined } from '@ant-design/icons';
import { faker } from '@faker-js/faker/locale/vi';
import { Button, Card, Col, Descriptions, Row, Space, Steps, Tabs } from 'antd';
import PageHeader from 'Components/Header/PageHeader';
import Master from 'Layouts/Master';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LogTab from './Tab/LogTab';
import NoteTab from './Tab/NoteTab';
import ProductTab from './Tab/ProductTab';
import TaskTab from './Tab/TaskTab';

const items = [
    {
        key: '1',
        label: 'Tên khách hàng',
        children: faker.person.fullName(),
    },
    {
        key: '2',
        label: 'Điện thoại',
        children: faker.phone.number(),
    },
    {
        key: '3',
        label: 'Địa chỉ giao hàng',
        children: faker.location.streetAddress(),
    },
    {
        key: '4',
        label: 'Hoàn Tất',
        children: 'empty',
    },
    {
        key: '5',
        label: 'Giá trị hợp đồng',
        children: '200,000,000',
    },
];

function DealDetailPage(props) {
    let { id } = useParams();
    const [current, setCurrent] = useState(0);
    const navigate = useNavigate();
    const onChange = (value, items) => {
        console.log('onChange:', value, items);
        setCurrent(value);
    };
    return (
        <Master>
            <PageHeader title={"Deal Detail " + id}>
                <Button size='large' color="danger" variant="solid" icon={<CloseCircleOutlined />} iconPosition={'start'} onClick={() => navigate('/deals')}>Đóng</Button>
            </PageHeader>
            <Space direction="vertical" style={{ width: '100%' }}>
                <Card bordered={true}>
                    <Steps
                        className="site-navigation-steps"
                        current={current}
                        onChange={onChange}
                        items={[
                            {
                                title: 'Báo Giá',
                                status: 'finish',
                                icon: <UserOutlined />,
                                onClick: () => console.log('click'),
                            },
                            {
                                title: 'Duyện Nội Bộ',
                                status: 'finish',
                                icon: <SolutionOutlined />,
                            },
                            {
                                title: 'Gửi Báo Giá',
                                status: 'finish',
                                icon: <PrinterOutlined />,
                            },
                            {
                                title: 'Lên Hợp Đồng',
                                status: 'finish',
                                icon: <FileWordOutlined />,
                            },
                            {
                                title: 'Hợp Đồng',
                                status: 'finish',
                                icon: <WalletOutlined />,
                            },
                            {
                                title: 'Sản Xuất',
                                status: 'process',
                                icon: <LoadingOutlined />,
                            },
                            {
                                title: 'Vận Chuyên',
                                status: 'wait',
                                icon: <ShoppingCartOutlined />,
                            },
                            {
                                title: 'Hoàn Tất',
                                status: 'wait',
                                icon: <SmileOutlined />,
                            },
                        ]}
                    />
                </Card>
            </Space>

            <Row gutter={24} style={{ marginTop: 16 }}>
                <Col span={6}>
                    <Descriptions title="Thông Tin Cơ Bản" layout="horizontal" bordered items={items} column={1} />
                    <Button type="primary" icon={<EditOutlined />}>Edit</Button>
                </Col>
                <Col span={18} >
                    <Card bordered={false}>
                        <Tabs
                            defaultActiveKey="1"
                            items={[
                                {
                                    label: `Nhật Ký`,
                                    key: '1',
                                    children: <LogTab />,
                                },
                                {
                                    label: `Ghi Chú`,
                                    key: '2',
                                    children: <NoteTab />,
                                },
                                {
                                    label: `Công Việc`,
                                    key: '3',
                                    children: <TaskTab />,
                                },
                                {
                                    label: `Sản Phẩm`,
                                    key: '4',
                                    children: <ProductTab />,
                                },

                            ]}
                        />
                    </Card>
                </Col>
            </Row>
        </Master>
    );
}

export default DealDetailPage;