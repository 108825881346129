import { ConfigProvider } from 'antd';
import { CustomerDetail, CustomerPage, DashboardPage, DealDetailPage, DealPage, ForgotPassword, LoginPage, ProductDetailPage, ProductPage, SettingPage, TaskPage, VendorDetailPage, VendorPage } from 'Page';

import { useEffect } from 'react';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import authStore from 'store/authStore';

const routerGuide = createBrowserRouter(
  createRoutesFromElements(
    <Route >
      <Route path="/" element={<LoginPage />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
    </Route>
  )
)

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route >
      <Route path="/" element={<DashboardPage />} />
      <Route path="deals" >
        <Route index element={<DealPage />} />
        <Route path=":id" element={<DealDetailPage />} />
      </Route>

      <Route path="customers" >
        <Route index element={<CustomerPage />} />
        <Route path=":id" element={<CustomerDetail />} />
      </Route>

      <Route path="products"  >
        <Route index element={<ProductPage />} />
        <Route path=":id" element={<ProductDetailPage />} />
      </Route>

      <Route path="vendors" >
        <Route index element={<VendorPage />} />
        <Route path=":id" element={<VendorDetailPage />} />
      </Route>
      <Route path="tasks" element={<TaskPage />} />
      <Route path="settings" element={<SettingPage />} />

    </Route>
  )
)

function App(props) {
  const { user } = authStore();

  useEffect(() => {
  }, [user])

  return (
    <ConfigProvider>
      <RouterProvider router={(user == null) ? routerGuide : router} />
    </ConfigProvider>
  );
}

export default App;