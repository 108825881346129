import { Row } from 'antd';
import React, { useEffect } from 'react';

function ProductTab(props) {
    useEffect(() => {

        console.log('ProductTab')
    }, [])
    return (
        <Row>
            Product
        </Row>
    );
}

export default ProductTab;