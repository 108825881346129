import { ShoppingCartOutlined } from '@ant-design/icons';
import { Button, Row, Table, Tag } from 'antd';
import { Link } from 'react-router-dom';

const columns = [
    {
        title: 'Sản phẩm',
        dataIndex: 'name',
        key: 'name',
        render: (text) => <Link>{text}</Link>,
    },
    {
        title: 'Giá bán',
        dataIndex: 'address',
        key: 'address',
    },
    {
        title: 'Số lượng',
        dataIndex: 'age',
        key: 'age',
    },
    {
        title: 'Nhà Cung Cấp',
        dataIndex: 'address',
        key: 'address',
    },
    {
        title: 'Chi tiết sản phẩm',
        key: 'tags',
        dataIndex: 'tags',
        render: (_, { tags }) => (
            <>
                {tags.map((tag) => {
                    let color = tag.length > 5 ? 'geekblue' : 'green';
                    if (tag === 'loser') {
                        color = 'volcano';
                    }
                    return (
                        <Tag color={color} key={tag}>
                            {tag.toUpperCase()}
                        </Tag>
                    );
                })}
            </>
        ),
    },
];

const data = [
    {
        key: '1',
        name: 'Sản phẩm 1',
        age: 10,
        address: ' Nhà cung cấp 1',
        tags: ['đẹp', 'khử trùng'],
    },
    {
        key: '2',
        name: 'Sản phẩm 2',
        age: 11,
        address: ' Nhà cung cấp 1',
        tags: ['đẹp', 'khử trùng', 'kho'],
    },
    {
        key: '3',
        name: 'Sản phẩm 3',
        age: 12,
        address: ' Nhà cung cấp 1',
        tags: ['đẹp', 'kho'],
    },
    {
        key: '4',
        name: 'Sản phẩm 4',
        age: 13,
        address: ' Nhà cung cấp 1',
        tags: ['kho'],
    },
];

const columnPride = [{
    // title: 'Sản phẩm',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    // render: (text) => <Link>{text}</Link>,
},
{
    // title: 'Giá bán',
    dataIndex: 'age',
    key: 'age',
    align: 'right',
},]
const dataPride = [
    {
        key: '1',
        name: <h3>Tổng Cộng</h3>,
        age: '10.000.000',
    },
    {
        key: '2',
        name: 'Chiết khấu',
        age: '10%',
    },
    {
        key: '3',
        name: <h2>Thành Tiền</h2>,
        age: <h2>9.000.000</h2>,

    },
]

function ProductTab(props) {
    return (
        <>
            <Row gutter={24} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                <Button type="primary" icon={<ShoppingCartOutlined />}>Edit</Button>
            </Row>
            <Row gutter={24}  >
                <Table columns={columns} dataSource={data} style={{ width: '100%' }} pagination={false}
                />
                <Table
                    showHeader={false}
                    columns={columnPride} dataSource={dataPride} style={{ width: '100%' }}
                    pagination={false} />
            </Row>
        </>
    );
}

export default ProductTab;