import { ArrowDownOutlined, ArrowUpOutlined, PlusCircleTwoTone, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Col, Input, Row, Statistic, Table, Tag } from 'antd';
import PageHeader from 'Components/Header/PageHeader';
import Master from 'Layouts/Master';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import CreateDrawer from './Drawer/CreateDrawer';
import { faker } from '@faker-js/faker/locale/vi';

const { Search } = Input;

const columns = [
    {
        title: 'Tên',
        dataIndex: 'name',
        key: 'name',
        fixed: 'left',
    },
    {
        title: 'Số điện thoại',
        dataIndex: 'tel',
        key: 'tel',
        fixed: 'left',
    },
    {
        title: 'Địa Chỉ',
        dataIndex: 'address',
        key: '1',
        // width: 150,
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: '2',
        // width: 150,
    },
    {
        title: 'Quận/Huyện',
        dataIndex: 'quan',
        key: '3',
        // width: 150,
    },
    {
        title: 'Tỉnh TP',
        dataIndex: 'tinh',
        key: '4',
        // width: 150,
    },
    {
        title: 'Nhóm khách hàng',
        dataIndex: 'group',
        key: 'group',
        render: (_, { group }) => (
            <>
                {group.map((tag) => {
                    let color = tag.length > 5 ? 'geekblue' : 'green';
                    if (tag === 'loser') {
                        color = 'volcano';
                    }
                    return (
                        <Tag color={color} key={tag}>
                            {tag.toUpperCase()}
                        </Tag>
                    );
                })}
            </>
        ),
    },
    {
        title: 'Người quản lý',
        dataIndex: 'user',
        key: 'user',
        width: 150,
    },
    {
        title: 'Ngày Tạo',
        dataIndex: 'created_at',
        key: 'created_at',
        // width: 150,
    },
    {
        title: 'Action',
        key: 'operation',
        fixed: 'right',
        // width: 100,
        render: (text) => <Link to={`/customers/${text.key}`}>View</Link>,
    },
];

const dataSource = Array.from({
    length: 100,
}).map((_, i) => ({
    key: i,
    name: faker.person.fullName(),
    tel: faker.phone.number(),
    email: faker.internet.email(),
    quan: faker.location.city(),
    tinh: faker.location.city(),
    address: faker.location.streetAddress(),
    user: faker.person.firstName(),
    age: 32,
    group: ['group 1', 'group 2', 'vip', 'fair', 'poor'],
    created_at: faker.date.recent().toISOString().split('T')[0],
    // address: `London Park no. ${i}`,
}));

function CustomerPage(props) {
    const [openProfile, setOpenOnProfile] = useState(false);
    const showDrawer = () => {
        setOpenOnProfile(true);
    };
    const onClose = () => {
        setOpenOnProfile(false);
    };
    return (
        <Master>
            <PageHeader title="Danh sách khách hàng">
                <Button size='large' type="primary" icon={<PlusCircleTwoTone />} iconPosition={'start'} onClick={showDrawer}>Thêm Khách Hàng</Button>
                <CreateDrawer showDrawer={showDrawer} onClose={onClose} open={openProfile} />
            </PageHeader>
            <Row gutter={24} style={{ marginBottom: 16 }} >
                <Col span={6}>
                    <Card bordered={false}>
                        <Statistic
                            title="Active"
                            value={11.28}
                            precision={2}
                            valueStyle={{ color: '#3f8600' }}
                            prefix={<ArrowUpOutlined />}
                            suffix="%"
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card bordered={false}>
                        <Statistic
                            title="Idle"
                            value={9.3}
                            precision={2}
                            valueStyle={{ color: '#cf1322' }}
                            prefix={<ArrowDownOutlined />}
                            suffix="%"
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card bordered={false}>
                        <Statistic
                            title="Active"
                            value={11.28}
                            precision={2}
                            valueStyle={{ color: '#3f8600' }}
                            prefix={<ArrowUpOutlined />}
                            suffix="%"
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card bordered={false}>
                        <Statistic
                            title="Idle"
                            value={9.3}
                            precision={2}
                            valueStyle={{ color: '#cf1322' }}
                            prefix={<ArrowDownOutlined />}
                            suffix="%"
                        />
                    </Card>
                </Col>
            </Row>
            <Row gutter={24} style={{ marginBottom: 16, textAlign: 'right', marginLeft: 5 }} >
                <Search
                    placeholder="input search text"

                    style={{
                        width: 200,
                        marginRight: 10,
                    }}
                /><Search
                    placeholder="input search text"

                    style={{
                        width: 200,
                        marginRight: 10,
                    }}
                /><Search
                    placeholder="input search text"

                    style={{
                        width: 200,
                        marginRight: 10,
                    }}
                /><Search
                    placeholder="input search text"

                    style={{
                        width: 200,
                        marginRight: 10,
                    }}
                /><Search
                    placeholder="input search text"

                    style={{
                        width: 200,
                        marginRight: 10,
                    }}
                /><Search
                    placeholder="input search text"

                    style={{
                        width: 200,
                        marginRight: 10,
                    }}
                /><Search
                    placeholder="input search text"

                    style={{
                        width: 200,
                        marginRight: 10,
                    }}
                />
                <Button type="primary" icon={<SearchOutlined />} iconPosition={'start'}>
                    Search
                </Button>

            </Row>
            <Table
                columns={columns}
                dataSource={dataSource}
                scroll={{
                    x: 1500,
                }}
                summary={() => (
                    <Table.Summary >
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={2}>

                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={2} colSpan={7}>
                                Scroll Context
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={10}>Fix Right</Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Table.Summary>
                )}
                // antd site header height
                sticky={{
                    offsetHeader: 64,
                }}
            />
        </Master>
    );
}

export default CustomerPage;