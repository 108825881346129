import { MailOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, Row } from 'antd';
import Guide from 'Layouts/Guide';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

function ForgotPassword(props) {
    return (
        <Guide>
            <Helmet>
                <title>Forgot Password</title>
            </Helmet>

            <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
                <Card title="Forgot Password" bordered={false} style={{ width: 350 }}>
                    <Form
                        name="login"
                        initialValues={{


                        }}
                        style={{
                            maxWidth: 360,
                        }}
                    >
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Email!',
                                },
                            ]}
                        >
                            <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email" />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" >
                                Submit
                            </Button>
                            <Link to="/" style={{ marginLeft: 10 }}>Login</Link>
                        </Form.Item>
                    </Form>
                </Card>
            </Row>
        </Guide>
    );
}

export default ForgotPassword;