import { Layout } from 'antd';
import React from 'react';

const { Content } = Layout;

function Guide(props) {
    return (
        <Layout>
            <Content style={{ minHeight: '100vh' }}>
                {props.children}
            </Content>
        </Layout>
    );
}

export default Guide;