import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Form, Input, Row, Space } from 'antd';
import { useNavigate } from 'react-router-dom';

function CreateDrawer(props) {
    const navigate = useNavigate();
    return (
        <Drawer
            title="Thêm Khách Hàng"
            width={600}
            onClose={props.onClose}
            open={props.open}
            styles={{
                body: {
                    paddingBottom: 100,
                },
            }}
            extra={
                <Space>
                    <Button size='large' onClick={() => navigate('/customers/2')} type="primary" icon={<PlusCircleOutlined />}>
                        Thêm
                    </Button>
                </Space>
            }
        >
            <Form layout="vertical" hideRequiredMark>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="name"
                            label="Name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter user name',
                                },
                            ]}
                        >
                            <Input type="text" placeholder="Please enter user name" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="email"
                            label="Email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter Email',
                                },
                            ]}
                        >
                            <Input type="email" placeholder="Please enter Email" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="address"
                            label="Địa Chỉ"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter Address',
                                },
                            ]}
                        >
                            <Input type="text" placeholder="Please enter Address" />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name="pasword"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter Password',
                                },
                            ]}
                        >
                            <Input type="password" placeholder="Tỉnh" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="heh"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter user Password',
                                },
                            ]}
                        >
                            <Input type="cfpassword" placeholder="Quận/Huyện" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="phuong"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter user Password',
                                },
                            ]}
                        >
                            <Input type="cfpassword" placeholder="Phường Xã" />
                        </Form.Item>
                    </Col>

                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="dateTime"
                            label="Số điện thoại"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please choose the dateTime',
                                },
                            ]}
                        >
                            <Input type="text" placeholder="Số điện thoại" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="dateTime"
                            label="Số điện thoại"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please choose the dateTime',
                                },
                            ]}
                        >
                            <Input type="text" placeholder="Số điện thoại" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="description"
                            label="Note"
                            rules={[
                                {
                                    required: true,
                                    message: 'please enter url description',
                                },
                            ]}
                        >
                            <Input.TextArea rows={4} placeholder="please enter url description" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    );
}

export default CreateDrawer;