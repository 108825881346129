import { Timeline } from 'antd';
import React from 'react';
import { faker } from '@faker-js/faker/locale/vi';

const items = () => {
    let item = [];
    for (let i = 0; i < 20; i++) {
        item.push({
            children: 'Thay đổi trạng thái ' + faker.date.recent(10),
        });
    }
    return item.sort();
}

items();

function LogTab(props) {
    return (
        <Timeline
            items={items()}
        />
    );
}

export default LogTab;