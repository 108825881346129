import { Button, Col, DatePicker, Drawer, Form, Input, Row, Space } from 'antd';

function ProfileDrawer(props) {
    return (
        <Drawer
            title="Update Profile"
            width={500}
            onClose={props.onClose}
            open={props.open}
            styles={{
                body: {
                    paddingBottom: 80,
                },
            }}
            extra={
                <Space>
                    <Button onClick={props.onClose}>Cancel</Button>
                    <Button onClick={props.onClose} type="primary">
                        Update
                    </Button>
                </Space>
            }
        >
            <Form layout="vertical" hideRequiredMark>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="name"
                            label="Name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter user name',
                                },
                            ]}
                        >
                            <Input type="text" placeholder="Please enter user name" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="email"
                            label="Email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter Email',
                                },
                            ]}
                        >
                            <Input type="email" placeholder="Please enter Email" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="pasword"
                            label="Password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter Password',
                                },
                            ]}
                        >
                            <Input type="password" placeholder="Please enter Password" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="cfemail"
                            label=" Confirm Password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter user Password',
                                },
                            ]}
                        >
                            <Input type="cfpassword" placeholder="Please enter Password" />
                        </Form.Item>
                    </Col>

                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="dateTime"
                            label="Birthday"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please choose the dateTime',
                                },
                            ]}
                        >
                            <DatePicker
                                style={{
                                    width: '100%',
                                }}
                                getPopupContainer={(trigger) => trigger.parentElement}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="description"
                            label="Note"
                            rules={[
                                {
                                    required: true,
                                    message: 'please enter url description',
                                },
                            ]}
                        >
                            <Input.TextArea rows={4} placeholder="please enter url description" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    );
}

export default ProfileDrawer;